<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

/**
 * Form  Gateways Credentials component
 */
export default {
  page: {
    title: "Formulário de Credenciais Gateway",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      form: {
        productionApiKey: '',
        productionSecretKey: '',
        sandboxApiKey: '',
        sandboxSecretKey: '',
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      productionApiKey: { required },
      productionSecretKey: { required },
      sandboxApiKey: { required },
      sandboxSecretKey: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    emitCad(event) {
      this.$emit('newCad', event);
    }
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <h4 class="text-center">
      Chaves de Produção
      <i
        class="fas fa-info-circle"
        v-b-tooltip.hover
        title="ATENÇÃO: Ambiente ideal para compras reais."
      >
      </i>
    </h4>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Chave API Key</label>
        <input
          id="api-key-production"
          v-model="form.productionApiKey"
          type="text"
          class="form-control"
          placeholder="Informe a chave api key (produção)"
          :class="{
            'is-invalid': submitted && $v.form.productionApiKey.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.productionApiKey.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.productionApiKey.required"
            >Por favor, digite a chave api.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Chave Secret Key</label>
        <input
          id="secret-key-production"
          v-model="form.productionSecretKey"
          type="text"
          class="form-control"
          placeholder="Informe a chave secret key (produção)"
          :class="{
            'is-invalid': submitted && $v.form.productionSecretKey.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.productionSecretKey.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.productionSecretKey.required"
            >Por favor, digite a chave secret.</span
          >
        </div>
      </div>
    </div>
    <h4 class="text-center mt-5">
      Chaves de Sandbox
      <i
        class="fas fa-info-circle"
        v-b-tooltip.hover
        title="ATENÇÃO: Ambiente ideal para compras testes."
      >
      </i>
    </h4>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Chave API Key</label>
        <input
          id="api-key-sandbox"
          v-model="form.sandboxApiKey"
          type="text"
          class="form-control"
          placeholder="Informe a chave api key (sandbox)"
          :class="{
            'is-invalid': submitted && $v.form.sandboxApiKey.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.sandboxApiKey.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.sandboxApiKey.required"
            >Por favor, digite a chave api.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Chave Secret Key</label>
        <input
          id="secret-key-sandbox"
          v-model="form.sandboxSecretKey"
          type="text"
          class="form-control"
          placeholder="Informe a chave secret key (sandbox)"
          :class="{
            'is-invalid': submitted && $v.form.sandboxSecretKey.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.sandboxSecretKey.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.sandboxSecretKey.required"
            >Por favor, digite a chave secret.</span
          >
        </div>
      </div>
    </div>
    <br />
    <div class="mt-2 text-end">
      <b-button variant="light" @click="emitCad(false)">Fechar</b-button>
      <b-button type="submit" variant="info" class="ms-1">Cadastrar</b-button>
    </div>
  </form>
</template>
