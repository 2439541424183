<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import FormGateway from "@/components/forms/form-gateways-credentials/index";
import appConfig from "@/app.config";

import { tableData } from "./data-advancedtable";

/**
 * Gateways Settings component
 */
export default {
  page: {
    title: "Credenciais de Gateways",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FormGateway,
  },
  data() {
    return {
      tableData: tableData,
      title: "Credenciais de Gateways",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Credenciais de Gateways",
          active: true,
        },
      ],  
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,  
      form: {
        name: "",
      },  
      fieldAliases: {
        id: "#",
        operator: "Operador de Pagamento",
        identification: "Identificação",
        extras: "Informações Extras",
        status: "Status",
        actions: "Ações",
      },    
    }; 
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "10%" } },
        { key: "operator", sortable: true, label: this.fieldAliases.operator },
        { key: "identification", sortable: true, label: this.fieldAliases.identification },
        { key: "extras", sortable: true, label: this.fieldAliases.extras, thStyle: { width: "30%" } },
        { key: "status", sortable: true, label: this.fieldAliases.status, thStyle: { width: "10%" } },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Nova Credencial'" @newCad="handleNewCad" />
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">Credenciais de Gateways</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(operator)="obj">
                  <div class="actions-cell">    
                    <strong> {{ obj.item.operator }} </strong>                 
                  </div>
                </template>
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">                    
                    <b-button class="w-100" pill @click="deleteItem(category.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <b-modal v-model="showModal" id="modal-dialog" title="Adicionar Credencial" title-class="font-18" hide-footer>
      <FormGateway @newCad="handleNewCad" />
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
