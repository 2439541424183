var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('h4',{staticClass:"text-center"},[_vm._v(" Chaves de Produção "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-info-circle",attrs:{"title":"ATENÇÃO: Ambiente ideal para compras reais."}})]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"title"}},[_vm._v("Chave API Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.productionApiKey),expression:"form.productionApiKey"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.productionApiKey.$error,
        },attrs:{"id":"api-key-production","type":"text","placeholder":"Informe a chave api key (produção)"},domProps:{"value":(_vm.form.productionApiKey)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "productionApiKey", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.productionApiKey.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.productionApiKey.required)?_c('span',[_vm._v("Por favor, digite a chave api.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"title"}},[_vm._v("Chave Secret Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.productionSecretKey),expression:"form.productionSecretKey"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.productionSecretKey.$error,
        },attrs:{"id":"secret-key-production","type":"text","placeholder":"Informe a chave secret key (produção)"},domProps:{"value":(_vm.form.productionSecretKey)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "productionSecretKey", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.productionSecretKey.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.productionSecretKey.required)?_c('span',[_vm._v("Por favor, digite a chave secret.")]):_vm._e()]):_vm._e()])]),_c('h4',{staticClass:"text-center mt-5"},[_vm._v(" Chaves de Sandbox "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-info-circle",attrs:{"title":"ATENÇÃO: Ambiente ideal para compras testes."}})]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"title"}},[_vm._v("Chave API Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sandboxApiKey),expression:"form.sandboxApiKey"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.sandboxApiKey.$error,
        },attrs:{"id":"api-key-sandbox","type":"text","placeholder":"Informe a chave api key (sandbox)"},domProps:{"value":(_vm.form.sandboxApiKey)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sandboxApiKey", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.sandboxApiKey.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sandboxApiKey.required)?_c('span',[_vm._v("Por favor, digite a chave api.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"form-label",attrs:{"for":"title"}},[_vm._v("Chave Secret Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sandboxSecretKey),expression:"form.sandboxSecretKey"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.form.sandboxSecretKey.$error,
        },attrs:{"id":"secret-key-sandbox","type":"text","placeholder":"Informe a chave secret key (sandbox)"},domProps:{"value":(_vm.form.sandboxSecretKey)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sandboxSecretKey", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.sandboxSecretKey.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sandboxSecretKey.required)?_c('span',[_vm._v("Por favor, digite a chave secret.")]):_vm._e()]):_vm._e()])]),_c('br'),_c('div',{staticClass:"mt-2 text-end"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.emitCad(false)}}},[_vm._v("Fechar")]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"info"}},[_vm._v("Cadastrar")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }