const tableData = [
  {
    id: 1,
    operator: 'Recruiting Mantotalname',
    identification: 10,
    extras: 'dont know',
    status: 'A',
  }, {
    id: 2,
    operator: 'Quality Engineer',
    identification: 23,
    extras: 'dont know',
    status: 'I',
  }, {
    id: 3,
    operator: 'Senior Editor',
    identification: 42,
    extras: 'dont know',
    status: 'A',
  }, {
    id: 4,
    operator: 'Programmer II',
    identification: 15,
    extras: 'dont know',
    status: 'A',
  },
  {
    id: 5,
    operator: 'Data Coordiator',
    identification:  17,
    extras: 'dont know',
    status: 'I',
  }, {
    id: 6,
    operator: 'Data Coordiator',
    identification: 2,
    extras: 'dont know',
    status: 'I',
  },
]

export { tableData };
